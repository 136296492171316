import { get } from 'lodash';
import { Router } from '@angular/router';
import { Course } from 'src/app/core/openapi';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';

import { AuthService } from 'src/app/services/auth/auth.service';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { CoursesService } from '../../../services/courses/courses.service';
import { ProgressService } from '../../../services/progress/progress.service';
import { ProgressManagerHelper } from 'src/app/shared/helpers/progress-manager';
import { CourseFEM, SectionFEM } from '../../../services/courses/courses.service.models';

interface SearchCourseResult extends Course {
  sections?: SectionFEM[];
}

@Component({
  selector: 'courses-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [
    trigger('fadeInStagger', [
      transition('* => *', [
        query(':enter', [style({ opacity: 0 }), stagger(80, [animate('0.3s ease', style({ opacity: 1 }))])], {
          optional: true,
        }),
      ]),
    ]),
  ],
})
export class CardComponent implements OnChanges {
  @Input() course: CourseFEM;
  @Input() foundedCourses: SearchCourseResult[] = [];
  @Input() courseId: string;
  @Input() sectionId: string;

  public disableImage: boolean = false;
  public foundedSearchList: SearchCourseResult[] = [];
  private _progressManager: ProgressManagerHelper;

  constructor(
    private router: Router,
    private _courses: CoursesService,
    private _rest: RestAPIService,
    private _auth: AuthService,
    private _progress: ProgressService,
  ) {
    this._progressManager = new ProgressManagerHelper(_rest);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.foundedCourses.firstChange && changes?.foundedCourses.currentValue) {
      this.handleWithSearch();
    }
  }

  public async navigateTo(courseId: string | undefined, destination: 'overview' | 'course'): Promise<void> {
    if (!courseId) return;

    const courseList = this._courses.getRawCourseData();
    const courseProgresses = this._courses.getRawProgressData();

    const course = courseList.find((c) => c.id === this.courseId);
    const progress = courseProgresses.find((p) => p.courseId === this.courseId);

    if (progress === undefined) {
      const user = await this._auth.getUser();

      const progress = await this._rest.post(
        'courseProgress/accountId/' + user.id,
        this._progressManager.createNewProgress(course.id, course.lessons),
      );

      this._progress.loadProgresses([progress]);
    } else {
      await this._progressManager.updateUserProgress(progress, course);
    }

    if (destination === 'overview') {
      this.router.navigate(['courses/overview/' + this.courseId]);
    } else {
      this.router.navigate(['courses/learning/' + this.courseId]);
    }
  }

  private handleWithSearch() {
    this.foundedSearchList = Array.isArray(this.foundedCourses) ? this.foundedCourses : [];
    this.disableImage = this.foundedSearchList.length > 0;
  }

  public goTo(lessonId: string): void {
    if (!lessonId) {
      this.router.navigate([`/courses/learning/${this.courseId}`]);
      return;
    }

    if (this.courseId && lessonId) {
      const checkIdIsSection = get(this.foundedSearchList, '0.sections[0].id', null);
      if (!checkIdIsSection) {
        this.router.navigate([`/courses/learning/${this.courseId}`], {
          queryParams: { section: lessonId },
        });
        return;
      }
      const firstSection = this.foundedSearchList.find((lesson) => lesson.id === lessonId)?.sections[0]?.id;

      this.router.navigate([`/courses/learning/${this.courseId}`], {
        queryParams: { section: firstSection },
      });
      return;
    }
  }
}
