<div class="mainContainer">
  <div class="header p-4 position-relative">
    <span
      *ngIf="progressList.length > 0"
      (click)="previousIndex()"
      class="clickable"
      [ngClass]="{ disabledIcon: activeIndex === 0 }"
    >
      <fa-icon
        class="mx-2"
        [icon]="arrowLeft"
      >
      </fa-icon>
    </span>
    Assessment Report {{ progressList.length > 0 ? activeIndex + 1 : '' }}
    <span
      *ngIf="progressList.length > 0"
      [ngClass]="{ disabledIcon: activeIndex + 1 === progressList.length }"
      (click)="nextIndex()"
      class="clickable"
    >
      <fa-icon
        class="mx-2"
        [icon]="arrowRight"
      >
      </fa-icon>
    </span>
  </div>

  <div
    *ngIf="records.length === 0"
    class="emptyRecords"
  >
    You dont have records for this session
  </div>

  <div *ngIf="records.length > 0">
    <div class="info">
      Questions answered: {{ getTotalQuestions() }} <br />
      Skipped Questions: {{ getSkippedQuestions() }} <br />
      Wrong answers:
      <span [ngClass]="{ wrongAnswer: getWrongAnswers() > 0 }">
        {{ getWrongAnswers() }} </span
      ><br />
      Points:
      <span [ngClass]="{ correctAnswer: getPoints() > 0 }">
        {{ getPoints() }}
      </span>
    </div>

    <div class="container p-3">
      <div
        class="reportBox p-2"
        *ngFor="let recordData of records; let i = index"
      >
        <div class="reportHeader">Question {{ i + 1 }}</div>
        <div class="question title">{{ recordData.question }}</div>
        <div *ngIf="recordData.content">
          <span class="title"> Text </span>:
          {{ recordData.content }}
        </div>
        <div class="choices">
          <span class="title"> Choices </span>:
          <span
            *ngFor="let choice of recordData.choices"
            [ngClass]="{ 
                      correctAnswer: shouldShowCorrectAnswer(recordData, choice), 
                      }"
          >
            {{
              recordData.choices.indexOf(choice) ===
              recordData.choices.lenght - 1
                ? choice
                : (choice + ',' | uppercase)
            }}
          </span>
        </div>
        <div class="selectedAnswer">
          <span class="title"> Selected Answer </span>:
          <span
            [ngClass]="{
              correctAnswer: isCorrectAnswer(
                recordData,
                recordData.selectedAnswer
              ),
              wrongAnswer: isWrongAnswer(recordData, recordData.selectedAnswer)
            }"
          >
            {{ recordData.selectedAnswer | uppercase }}
          </span>
        </div>
        <div class="skipped">
          <span class="title"> Skipped </span>:
          {{ recordData.skipped ? 'Yes' : 'No' }}
        </div>
        <div class="time">
          <span class="title"> At time </span>:
          {{ recordData.time }}
        </div>
      </div>
    </div>
  </div>

  <div class="button d-flex justify-content-center my-3">
    <app-simple-button
      [text]="'Close'"
      (click)="close()"
    >
    </app-simple-button>
  </div>
</div>
