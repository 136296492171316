import { Injectable } from '@angular/core';
import { Category } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { GameTag } from 'src/app/pages/program/consts/program-game-consts';
import { startCase } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DatepickerService {
  constructor() {}

  public maxIterationDays = 120;

  public studentAgenda = {};

  public datePicker = (startDate: Date, weekDays: number[], categories: Category[]) =>
    this.studentAgendaBuilder(startDate, weekDays, categories);

  public reset() {
    this.studentAgenda = {};
  }

  private studentAgendaBuilder(startDate: Date, weekDays: number[], categories: Category[]) {
    this.reset();

    const mandatoryCategories = categories.filter((c) => c.mandatory);
    const firstCategory = mandatoryCategories.find(
      (c) => c.unlockCondition.filter((condition) => condition.name !== startCase(GameTag.Assessment)).length === 0,
    );
    const lockedCategories = mandatoryCategories.filter(
      (c) => c.unlockCondition.filter((condition) => condition.name !== startCase(GameTag.Assessment)).length > 0,
    );

    const days = weekDays.length;
    const daysToCompleteFirstCategory = (firstCategory.maxSessions / days) * 7;

    let iterableDate = new Date(startDate);

    for (const category of mandatoryCategories) {
      this.studentAgenda[category.title] = [];
    }

    for (let i = 0; i < daysToCompleteFirstCategory; i++) {
      const day = iterableDate.getDay();
      const isDayAllowed: boolean = weekDays.find((d) => d === day) !== undefined;

      iterableDate = this.getIterableDate(isDayAllowed, iterableDate, firstCategory.title);
    }

    for (const category of lockedCategories) {
      const daysToCompleteCategory = (category.maxSessions / days) * 7;
      const highestSessionCondition = category.unlockCondition.reduce(
        (max, condition) => (condition.session > max.session ? condition : max),
        category.unlockCondition[0],
      );

      const conditionCategory = mandatoryCategories.find((c) => c.name === highestSessionCondition.name);

      const relatedAgenda = this.studentAgenda[conditionCategory.title];
      const start = new Date(relatedAgenda[highestSessionCondition.session - 1]);

      for (let i = 1; i < daysToCompleteCategory; i++) {
        const date = new Date(start).setDate(start.getDate() + i);
        const newDate = new Date(date);
        const allowedDays = weekDays.find((d) => d === newDate.getDay());

        if (allowedDays) {
          this.studentAgenda[category.title].push(newDate);
        }
      }
    }

    return this.studentAgenda;
  }

  public getIterableDate(isDayAllowed: boolean, iterableDate: Date, categoryTitle: string) {
    if (isDayAllowed) {
      this.studentAgenda[categoryTitle].push(iterableDate);
      const newDate = new Date(iterableDate).setDate(iterableDate.getDate() + 1);
      iterableDate = new Date(newDate);
    } else {
      const newDate = new Date(iterableDate).setDate(iterableDate.getDate() + 1);
      iterableDate = new Date(newDate);
    }

    return iterableDate;
  }
}
