<ng-container *ngIf="course">
  <div class="wrapper">
    <header>
      <h2 class="course-title">{{ course?.title }}</h2>
      <div class="course-lesson-count">
        <span class="lesson-count-number">{{ course?.numberOfLessons }}</span>
        <span class="lesson-count-text">{{
          course?.numberOfLessons === 1 ? 'Lesson' : 'Lessons'
        }}</span>
      </div>
      <div class="progress-container">
        <div class="progress-bar">
          <div
            class="progress-fill"
            [style.width.%]="course?.percentOfCourseComplete"
          ></div>
        </div>
        <span class="progress-text"
          >{{ course?.percentOfCourseComplete }}% Complete</span
        >
      </div>
    </header>

    <ng-container *ngIf="disableImage">
      <div class="search-results-container">
        <div class="search-results-header">
          <svg
            class="search-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
          >
            <path
              d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            />
          </svg>
          <h3 class="search-results-title">Search result</h3>
        </div>
        <div class="search-results-list">
          <ng-container
            *ngIf="foundedSearchList && foundedSearchList.length > 0"
          >
            <div [@fadeInStagger]="foundedSearchList.length">
              <div
                class="search-result-item"
                *ngFor="let lesson of foundedSearchList; let i = index"
                [class.search-result-item--odd]="i % 2 !== 0"
              >
                <div class="search-result-content">
                  <div class="search-result-index">{{ i + 1 }}</div>
                  <div class="search-result-info">
                    <h4
                      class="search-result-title"
                      title="{{ lesson.title }}"
                    >
                      {{ lesson.title }}
                    </h4>
                  </div>
                </div>
                <button
                  class="search-result-button"
                  (click)="goTo(lesson.id)"
                >
                  <span>Go</span>
                  <svg
                    class="arrow-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <nav>
      <button
        class="course-button overview-button"
        (click)="navigateTo(course?.id, 'overview')"
      >
        See Overview
      </button>
      <button
        class="course-button start-button"
        (click)="navigateTo(course?.id, 'course')"
      >
        {{ course?.hasCourseStarted ? 'Resume Course' : 'Start Course' }}
      </button>
    </nav>

    <div
      class="course-image"
      *ngIf="!disableImage"
    >
      <img
        [src]="course?.image"
        [alt]="course?.title"
      />
    </div>
  </div>
</ng-container>
