<div
  class="calendar"
  *ngIf="!showStartDate && !showCalendar"
>
  <div class="header">
    Choose the days of the week you would like to do the Neuralign program
  </div>
  <div class="calendarDaysHeader d-flex mt-2 mx-auto p-2">
    <div
      class="days mx-2"
      *ngFor="let options of weekDaysOptions"
      (click)="getWeekDays(options)"
      [ngClass]="{ selectedDay: findSelectedDays(options) }"
    >
      {{ options.label }}
    </div>
  </div>
  <div class="confirmButton col-12 d-flex justify-content-center">
    <button
      [ngClass]="{ disabledBtn: weekDays.length === 0 }"
      [disabled]="weekDays.length === 0"
      mat-raised-button
      class="submitBtn mt-3 mx-2"
      (click)="showStartDate = true"
    >
      Submit
    </button>

    <button
      mat-raised-button
      class="submitBtn mt-3 mx-2"
      (click)="close()"
    >
      Cancel
    </button>
  </div>
</div>

<div
  class="optionsContainer"
  *ngIf="!showCalendar && showStartDate"
>
  <div class="mainContainer col-12 px-0">
    <div class="header col-12 px-0 text-center">Neuralign</div>
    <div class="options col-12 p-3">
      <div class="optionsHeader p-2">
        Would you like to start Neuralign on ...
      </div>
      <div class="p-2 mt-4">
        <mat-form-field
          appearance="fill"
          [ngClass]="{'hideInput': newCalendar}"
        >
          <mat-label>Choose the starting date</mat-label>
          <input
            matInput
            [min]="today"
            [matDatepicker]="dp3"
            [(ngModel)]="startDate"
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dp3"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #dp3
            disabled="false"
            [startAt]="today"
          >
          </mat-datepicker>
        </mat-form-field>

        <div class="d-flex justify-content-center mt-2">
          <button
            mat-raised-button
            (click)="fillCalendar()"
            [disabled]="!startDate"
            [ngClass]="{ submitBtn: startDate }"
            class="mx-1"
          >
            Submit
          </button>

          <button
            mat-raised-button
            (click)="showStartDate = false"
            class="submitBtn mx-1"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="calendarContainer"
  *ngIf="showCalendar"
>
  <div
    class="calendarHeader d-flex mx-0 px-0 justify-content-center align-items-center"
  >
    <fa-icon
      class="calendarIcon mx-2"
      [icon]="arrowLeft"
      mwlCalendarPreviousView
      [view]="view"
      [(viewDate)]="viewDate"
      (click)="closeOpenMonthViewDay()"
    ></fa-icon>
    <div>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</div>
    <fa-icon
      class="calendarIcon mx-2"
      [icon]="arrowRight"
      mwlCalendarNextView
      [view]="view"
      [(viewDate)]="viewDate"
      (click)="closeOpenMonthViewDay()"
    ></fa-icon>
  </div>
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="'month'"
      [viewDate]="viewDate"
      [events]="events"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="eventClicked($event)"
      [activeDayIsOpen]="activeDayIsOpen"
    >
    </mwl-calendar-month-view>
  </div>

  <div class="calendarFooter mt-3">
    <div *ngFor="let category of mandatoryCategories">
      <span
        [style]="getLabelColor(category)"
        class="label"
      ></span>
      {{ category.name }}
    </div>
  </div>

  <div class="submit d-flex mx-0 px-0 justify-content-center">
    <button
      mat-raised-button
      class="change submitBtn mx-2"
      (click)="changeStartDate()"
      *ngIf="!allowDateEdition"
    >
      Change start date
    </button>
    <button
      mat-raised-button
      class="submit submitBtn mx-2"
      (click)="save()"
      *ngIf="selectedAgenda.saveMode === saveMode.SAVE"
    >
      Save
    </button>
    <button
      mat-raised-button
      class="submit submitBtn mx-2"
      (click)="updateAgenda()"
      *ngIf="allowDateEdition && selectedAgenda.saveMode === saveMode.UPDATE "
    >
      Update
    </button>
  </div>
</div>
