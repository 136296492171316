import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

/**
 * This interceptor adds the Authorization token to every request, assuming it can
 * find the user's id token from the AuthService.
 */
@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.accessToken) {
      if (this.auth.isExpired()) {
        this.auth.logout();
        return;
      }

      // If the token expiration is under our refresh threshold, refresh it
      if (this.auth.shouldRefresh()) {
        this.auth.refreshToken();
      }

      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.idToken}`,
        },
      });
      return next.handle(authReq);
    }

    return next.handle(req);
  }
}
