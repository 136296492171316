<div class="main">
  <div
    class="container-loading"
    *ngIf="initLoading"
  >
    <div class="loading-students">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div
    class="container"
    *ngIf="!initLoading"
  >
    <div class="title">
      <h2 *ngIf="data.type == 'create'">Create Student</h2>
      <h2 *ngIf="data.type == 'update'">Update Student</h2>
    </div>

    <div class="profile-photo-container">
      <div
        *ngIf="studentForm.image"
        class="profile-photo-preview"
      >
        <img
          [src]="
            studentForm.image || './assets/img/StudentImagePlaceholder.png'
          "
          alt="Student photo preview"
          class="preview-image"
          (click)="addStudentImage()"
        />
        <div class="hover-content">
          <button (click)="addStudentImage()">
            <fa-icon [icon]="camera"></fa-icon>
            <span>Change your picture</span>
          </button>
        </div>
      </div>
      <div
        *ngIf="!studentForm.image"
        class="profile-photo-button"
      >
        <button (click)="addStudentImage()">
          <fa-icon [icon]="camera"></fa-icon>
        </button>
      </div>
    </div>

    <form
      #form="ngForm"
      novalidate
    >
      <div class="input-group">
        <div>
          <mat-form-field class="my-2">
            <mat-label>Full Name</mat-label>
            <input
              #fullname="ngModel"
              matInput
              required
              placeholder="Nickname"
              [(ngModel)]="studentForm.fullname"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
          <mat-error *ngIf="fullname.invalid && fullname.touched">
            Full name is required.
          </mat-error>
        </div>

        <div>
          <mat-form-field class="my-2">
            <mat-label>Nickname</mat-label>
            <input
              #nickname="ngModel"
              matInput
              required
              placeholder="Nickname"
              [(ngModel)]="studentForm.nickname"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
          <mat-error *ngIf="nickname.invalid && nickname.touched">
            Nickname is required.
          </mat-error>
        </div>
      </div>

      <div
        *ngIf="isSchoolSetup"
        class="input-group"
      >
        <div>
          <mat-form-field class="my-2">
            <mat-label>Email</mat-label>
            <input
              #email="ngModel"
              matInput
              required
              placeholder="E-mail"
              [(ngModel)]="studentEmail"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
          <mat-error *ngIf="email.invalid && email.touched">
            Email is required.
          </mat-error>
        </div>
        <div>
          <mat-form-field class="my-2">
            <mat-label>Grade</mat-label>
            <input
              #grade="ngModel"
              type="number"
              min="0"
              matInput
              required
              placeholder="Grade"
              [(ngModel)]="studentForm.grade"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
          <mat-error *ngIf="grade.invalid && grade.touched">
            Grade is required.
          </mat-error>
        </div>
      </div>

      <!-- input-group select(theme e language) -->
      <div class="input-group">
        <mat-form-field class="my-2">
          <mat-label>Theme</mat-label>
          <mat-select
            #theme="ngModel"
            (ngModelChange)="onThemeChange($event)"
            [(ngModel)]="studentForm.theme"
            [ngModelOptions]="{ standalone: true }"
          >
            <option
              value=""
              disabled
              selected
            >
              Select Theme
            </option>
            @for (theme of themes; track theme) {
            <mat-option [value]="theme.id">{{ theme.label?.en_ca }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="theme.invalid && theme.touched">
          Theme is required.
        </mat-error>

        <mat-form-field class="my-2">
          <mat-label>Language</mat-label>
          <mat-select
            #language="ngModel"
            [(ngModel)]="studentForm.language"
            [ngModelOptions]="{ standalone: true }"
          >
            <option
              value=""
              disabled
              selected
            >
              Select Language
            </option>
            @for (lang of languagesTheme; track lang) {
            <mat-option [value]="lang.languageCode">{{ lang.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="language.invalid && language.touched">
          Language is required.
        </mat-error>
      </div>
      <!-- input-block birthdate -->
      <div class="d-flex justify-content-center">
        <div>
          <mat-form-field class="my-2">
            <mat-label>Birthdate (MM/YYYY)</mat-label>
            <input
              matInput
              [matDatepicker]="dp"
              [formControl]="date"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="dp"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #dp
              startView="multi-year"
              (monthSelected)="setMonthAndYear($event, dp)"
            >
            </mat-datepicker>
          </mat-form-field>
          <mat-error *ngIf="date.invalid && date.touched">
            You must enter a valid date.
          </mat-error>
        </div>
      </div>

      <div class="student-config">
        <div class="student-config-left">
          <div class="checkbox-container">
            <label class="custom-checkbox">
              <input
                type="checkbox"
                id="studentForm.enableHomeAccess"
                name="enableHomeAccess"
                [(ngModel)]="studentForm.enableHomeAccess"
              />
              <span class="checkmark"></span>
              <span class="checkbox-label">Allow home access</span>
            </label>
          </div>
          <div class="checkbox-container">
            <label class="custom-checkbox">
              <input
                type="checkbox"
                id="studentForm.alertOnSessionEnd"
                name="alertOnSessionEnd"
                [(ngModel)]="studentForm.alertOnSessionEnd"
              />
              <span class="checkmark"></span>
              <span class="checkbox-label"
                >Send me an email when student completes a session</span
              >
            </label>
          </div>
        </div>
        <div class="stutend-config-right">
          <button
            [ngClass]="{ disabled: !studentForm.theme }"
            *ngIf="!checkIfHasSavedAgenda()"
            class="btn-primary"
            (click)="openOptionsDialog()"
          >
            <fa-icon [icon]="calendar"></fa-icon>
            <span>Create Agenda</span>
          </button>

          <button
            *ngIf="checkIfHasSavedAgenda()"
            class="btn-primary"
            (click)="openStudentAgenda()"
          >
            <fa-icon [icon]="calendar"></fa-icon>
            <span>Open Agenda</span>
          </button>
        </div>
      </div>

      <div
        class="button-group"
        *ngIf="!saveLoading"
      >
        <button
          class="btn-secondary"
          (click)="close()"
        >
          Close
        </button>
        <button
          [disabled]="!form.valid"
          [ngClass]="{ disabled: shouldDisableSubmitBtn() }"
          class="btn-primary"
          (click)="save()"
        >
          Save
        </button>
      </div>

      <div
        class="loading mt-2"
        *ngIf="saveLoading"
      >
        <fa-icon
          class="mx-2"
          [icon]="spinnerIcon"
          [spin]="true"
        >
        </fa-icon>

        <div>Please wait, the student is being saved.</div>
      </div>
    </form>
  </div>
</div>
