import { Injectable } from '@angular/core';
import { Organization } from 'src/app/core/openapi';

@Injectable({
  providedIn: 'root',
})
export class PortalTypeService {
  private isOrgsPortal = false;
  private orgName = '';
  private portalOwner: Organization;

  // list of hostnames we have for every time a user access the portal without using an org url
  public regularPortalHosts = ['ls.works', 'lsworks.dev', 'localhost'];

  constructor() {}

  public get chekIfIsOrgPortal() {
    return this.isOrgsPortal;
  }

  public get getOrgName() {
    return this.orgName;
  }

  public get getPortalOwner() {
    return this.portalOwner;
  }

  public setOrgPortal(value: boolean) {
    this.isOrgsPortal = value;
  }

  public setOrgName(value: string) {
    this.orgName = value;
  }

  public setPortalOwner(org: Organization) {
    this.portalOwner = org;
  }

  public checkIfIsSchool() {
    return this.portalOwner.isSchool;
  }
}
