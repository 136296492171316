import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faArrowRight, faArrowLeft, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

@Component({
  selector: 'assessment-report-dialog',
  templateUrl: 'assessment-report.component.html',
  styleUrls: ['assessment-report.component.scss'],
})
export class AssessmentReportDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public _dialog: MatDialog,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<AssessmentReportDialog>,
  ) {}

  public records = [];
  public activeIndex = 0;
  public progressList = [];

  public readonly arrowRight: IconDefinition = faArrowRight;
  public readonly arrowLeft: IconDefinition = faArrowLeft;

  ngOnInit(): void {
    this.records = this.data.records ?? [];
    this.progressList = this.data.progressList ?? [];
  }

  public nextIndex() {
    if (this.activeIndex + 1 === this.progressList.length) {
      return;
    }
    this.activeIndex += 1;
    this.records = get(this.progressList[this.activeIndex], 'metadata.records', []);
  }

  public previousIndex() {
    if (this.activeIndex === 0) {
      return;
    }
    this.activeIndex -= 1;
    this.records = get(this.progressList[this.activeIndex], 'metadata.records', []);
  }

  public close() {
    this.dialogRef.close();
  }

  public isCorrectAnswer(data, choice): boolean {
    return choice === data.correctAnswer || (data.isCorrect && choice === data.selectedAnswer);
  }

  public getTotalQuestions() {
    const nonSampleData = this.records.filter((d) => !d.isSample);
    return nonSampleData.length;
  }

  public isWrongAnswer(data, choice): boolean {
    return !data.isCorrect;
  }

  public shouldShowCorrectAnswer(data, choice): boolean {
    return !data.isCorrect && choice === data.correctAnswer;
  }

  public getSkippedQuestions() {
    const skippedQuestions = this.records.filter((d) => d.skipped);
    return skippedQuestions.length;
  }

  public getPoints() {
    const nonSampleData = this.records.filter((d) => !d.isSample);
    const correctAnswers = nonSampleData.filter((d) => d.isCorrect);
    return correctAnswers.length;
  }

  public getWrongAnswers() {
    const nonSampleData = this.records.filter((d) => !d.isSample && !d.skipped);
    const wrongAnswers = nonSampleData.filter((d) => !d.isCorrect);
    return wrongAnswers.length;
  }
}
