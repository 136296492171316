import { Injectable } from '@angular/core';
import { classic } from 'src/app/shared/consts/themeLabels';
import { RestAPIService } from '../rest/rest-api.service';
import { RoleService } from '../roles/role.service';
import { Theme } from 'src/app/pages/configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { GlobalConfigurationHelper } from '../utils/global-configuration-helper';
import { Category } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private enabledThemes: Theme[];
  private classicTheme: Theme;

  constructor(
    private roles: RoleService,
    private _rest: RestAPIService,
    private globalConfigHelper: GlobalConfigurationHelper,
  ) {}

  public async getEnabledThemes() {
    if (this.enabledThemes) {
      return this.enabledThemes;
    } else {
      const url = this.roles.isB2C() ? 'themes/getB2cAllowedThemes' : 'themes/findAllowedThemes';

      const themes = await this._rest.get(url, { msg: 'Could not get the enabled themes' });

      if (themes) {
        this.enabledThemes = themes.filter((t: Theme) => t.isEnabled);
        this.classicTheme = themes.find((t: Theme) => t.isDefault && t.variableName === classic);
        return this.enabledThemes;
      } else {
        return [];
      }
    }
  }

  public async getThemeCategories(theme: Theme) {
    const categories = await this.globalConfigHelper.getCategories();
    const themeCategories: Category[] = theme.categories.map((c) => {
      const relatedCategory = categories.find((category) => category.id === c.id);

      if (relatedCategory) {
        return relatedCategory;
      }

      return c;
    }) as Category[];

    return themeCategories;
  }

  public async findTheme(themeId: string) {
    const themes = await this.getEnabledThemes();

    if (!themes) {
      return null;
    }

    return themes.find((t) => t.id === themeId);
  }

  public getClassicTheme() {
    if (!this.classicTheme) {
      this.classicTheme = this.enabledThemes.find((t: Theme) => t.isDefault && t.variableName === classic);
    } else {
      return this.classicTheme;
    }
  }
}
