import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { CalendarEvent, CalendarView, CalendarMonthViewDay } from 'angular-calendar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatepickerService } from './datepicker-service/datepicker-service.service';
import { DateChangerDialogComponent } from './date-changer-dialog/date-changer-dialog';
import { forEach } from 'lodash';
import { SAVE_MODE } from './consts/save-mode';
import { ThemeService } from 'src/app/services/themes/themes.service';
import { Theme } from 'src/app/pages/configuration-pages/content-configurations/components/themes/interfaces/themes.interface';
import { Categories } from 'src/app/core/openapi';
import { get } from 'lodash';

export interface EventModel {
  cognitiveTherapy: [];
  readingExercises: [];
  speedReading: [];
}

export interface ColourData {
  [key: string]: string;
}

export interface Colours {
  [key: string]: ColourData;
}

// Add the colors on the
const colors: Colours = {
  cognitiveTherapy: {
    primary: '#ad2121',
  },
  readingExercises: {
    primary: '#1e90ff',
  },
  speedReading: {
    primary: '#e3bc08',
  },
  default: {
    primary: '#ad2121',
  },
};

@Component({
  selector: 'app-calendar-dialog',
  // eslint-disable-next-line
  encapsulation: ViewEncapsulation.None,
  templateUrl: './calendar.dialog.html',
  styleUrls: ['./calendar.dialog.scss'],
})
export class CalendarDialogComponent implements OnInit {
  public activeDayIsOpen = false;
  public showStartDate = false;
  public readonly saveMode = SAVE_MODE;
  public readonly arrowRight: IconDefinition = faArrowRight;
  public readonly arrowLeft: IconDefinition = faArrowLeft;
  public options = [
    {
      id: 'calendar15DaysInARow',
      label: '15 days in a row',
    },
    {
      id: 'calendar3Weeks',
      label: '3 Weeks',
    },
    {
      id: 'calendar4Weeks',
      label: '4 Weeks',
    },
    {
      id: 'calendar5Weeks',
      label: '5 Weeks',
    },
  ];

  public weekDaysOptions = [
    {
      day: 'Sunday',
      value: 0,
      label: 'SUN',
    },
    {
      day: 'Monday',
      value: 1,
      label: 'MON',
    },
    {
      day: 'Tuesday',
      value: 2,
      label: 'TUE',
    },
    {
      day: 'Wednesday',
      value: 3,
      label: 'WED',
    },
    {
      day: 'Thursday',
      value: 4,
      label: 'THU',
    },
    {
      day: 'Friday',
      value: 5,
      label: 'FRI',
    },
    {
      day: 'Saturday',
      value: 6,
      label: 'SAT',
    },
  ];
  public selectedOption: string;
  public startDate: Date;
  public weekDays = [];
  public today = new Date();
  public studentAgenda: any;
  public newCalendar = false;
  public agenda: any;
  public showCalendar = false;
  public allowDateEdition = false;
  private eventTitleMap = {
    cognitiveTherapy: 'Cognitive Therapy',
    readingExercises: 'Reading Exercises',
    speedReading: 'Speed Reading',
  };
  private theme: Theme = null;

  constructor(
    public datePickerService: DatepickerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CalendarDialogComponent>,
    protected _snackBar: MatSnackBar,
    private themeService: ThemeService,
    @Inject(MAT_DIALOG_DATA) public selectedAgenda: any,
  ) {}

  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();

  events: CalendarEvent[] = [];

  public mandatoryCategories = [];
  public categoriesList = [];
  public labelList = [];

  async ngOnInit() {
    if (this.selectedAgenda.agenda) {
      this.agenda = this.selectedAgenda.agenda;
      this.fillStudentAgenda();
      this.showCalendar = true;
      this.allowDateEdition = true;
    }

    this.theme = await this.themeService.findTheme(this.selectedAgenda.themeId);
    this.categoriesList = (await this.themeService.getThemeCategories(this.theme)) ?? [];
    this.mandatoryCategories = this.categoriesList.filter((c) => c.mandatory);
  }

  public getWeekDays(options) {
    const findWeekDay = this.weekDays.find((d) => d === options.value);
    if (findWeekDay !== undefined) {
      const index = this.weekDays.indexOf(findWeekDay);
      this.weekDays.splice(index, 1);
    } else {
      this.weekDays.push(options.value);
    }
  }

  public findSelectedDays(options): boolean {
    const day = this.weekDays.find((d) => d === options.value);
    return day !== undefined;
  }

  private async fillCalendar() {
    const categories = await this.themeService.getThemeCategories(this.theme);
    const buildAgenda = this.datePickerService.datePicker;
    const agenda = buildAgenda(this.startDate, this.weekDays, categories);

    this.agenda = agenda;
    this.showCalendar = true;

    this.concatArrays();
  }

  private fillStudentAgenda() {
    if (!this.agenda) {
      return;
    }

    for (const keys of Object.keys(this.agenda)) {
      const category = this.categoriesList.find((c) => c.title === keys);

      this.agenda[keys] = this.agenda[keys].map((d) => {
        return {
          title: category ? category.name : this.eventTitleMap[keys],
          start: new Date(d.start),
          color: colors[keys] ? colors[keys] : colors.default,
        };
      });
    }

    this.events = Object.values(this.agenda).flat() as CalendarEvent[];
  }

  public getLabelColor(category: Categories) {
    return 'background-color:' + get(this.agenda[category.title][0], 'color.primary', '#ad2121');
  }

  public concatArrays() {
    if (!this.agenda) {
      return;
    }

    for (const keys of Object.keys(this.agenda)) {
      const category = this.categoriesList.find((c) => c.title === keys);

      this.agenda[keys] = this.agenda[keys].map((d) => {
        return {
          title: category ? category.name : this.eventTitleMap[keys],
          start: d,
          color: colors[keys] ? colors[keys] : colors.default,
        };
      });
    }

    this.events = Object.values(this.agenda).flat() as CalendarEvent[];
  }

  public closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  public changeStartDate() {
    this.activeDayIsOpen = false;
    this.showCalendar = false;
  }

  public save() {
    this._snackBar.open('Student agenda has been saved', 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });

    this.removeColorFromAgenda();

    this.dialogRef.close(this.agenda);
  }

  public dayClicked(day: CalendarMonthViewDay) {
    if (day.date.toDateString() === this.viewDate.toDateString()) {
      this.activeDayIsOpen = !this.activeDayIsOpen;
    }

    const checkIfHaveEvent = this.events.find((e) => e.start.toDateString() === day.date.toDateString());

    if (checkIfHaveEvent) {
      this.activeDayIsOpen = true;
    } else {
      this.activeDayIsOpen = false;
    }

    this.viewDate = day.date;
  }

  public eventClicked({ event }: { event: CalendarEvent }): void {
    const dialog = this.dialog.open(DateChangerDialogComponent, {
      data: event,
      width: '350px',
      panelClass: 'modal-border',
    });
    dialog.afterClosed().subscribe((data) => {
      if (!data) {
        return;
      }

      const index = this.events.indexOf(event);
      this.events[index].start = data;
      this.viewDate = data;
    });
  }

  public updateAgenda() {
    this.removeColorFromAgenda();
    this.dialogRef.close(true);
  }

  public removeColorFromAgenda() {
    forEach(this.agenda, (program) => {
      forEach(program, (calendar) => {
        delete calendar.color;
      });
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
