import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { RestAPIService } from 'src/app/services/rest/rest-api.service';
import { environment } from 'src/environments/environment';
import { getPhonemefairUnityConfig, getUnityConfig } from './game.utils';
import { QueryData, GameStundentDTO, GameDTO, SessionConfigs } from './game.interface';
import { get } from 'lodash';
import { SessionSkipData, UNICODE_OVERRIDE } from './game.constants';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { HttpParams } from '@angular/common/http';
import { Progress } from '../../models/progress.model';
import { progressTypes } from '../../../shared/consts/global-constants';
import { VIDEO } from 'src/app/pages/program/consts/program-game-consts';
import { classic } from '../../consts/themeLabels';
import { UnityMessages } from './game.constants';
import { ActivatedRoute } from '@angular/router';
import { Category } from 'src/app/pages/configuration-pages/interfaces/global-config.interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { captureException } from '@sentry/angular-ivy';
import { isEmpty } from 'lodash';
import { StudentHelperService } from 'src/app/services/student/student-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { SystemRequirementsDialogComponent } from '../../dialogs/system-requirements/system-requirements.dialog';
import { firstValueFrom } from 'rxjs';
import { Phonemefair, PhonemeFairControllerService } from 'src/app/core/openapi';

declare let createUnityInstance: any;

enum BuildType {
  PHOENEME_FAIR = 'phonemefair',
  DEFAULT = 'default',
}

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit, OnDestroy {
  @Input() queryData: QueryData;
  @Input() student: GameStundentDTO;
  @Input() overwriteSessionDetails: SessionConfigs;
  @Input() program = 'Neuralign';
  @Input() gameDataOverwrite: GameDTO;
  @Input() savedGameProgress: Progress[] = [];
  @Input() studentProgress: Progress[] = [];
  @Input() studentThemeId: string;
  @Input() selectedLesson: Category;
  @Input() isDebugMode = false;
  @Input() demoName: string;

  @Output() sessionComplete = new EventEmitter<any>();
  @Output() saveProgress = new EventEmitter<any>();
  @Output() endUnitySession = new EventEmitter<any>();
  @Output() handleUnityClose = new EventEmitter<boolean>();

  public isMobileDevice: boolean = false;

  public gameInstance: any;
  public loading = true;
  public loadGameInstance = false;
  private unityConfig = getUnityConfig();
  public studentId = '';
  public phonemefairSave: Phonemefair;
  private script;

  constructor(
    private rest: RestAPIService,
    private logger: LoggerService,
    private _render: Renderer2,
    private _activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private studentHelper: StudentHelperService,
    private dialog: MatDialog,
    private _phonemeFairService: PhonemeFairControllerService,
  ) {}

  async ngOnInit() {
    try {
      this.studentId = this._activatedRoute.snapshot.paramMap.get('studentId');
      const categoryName = get(this.selectedLesson, 'name') || this.demoName;
      if (categoryName?.toLowerCase() === progressTypes.COGNITIVE_THERAPY) {
        this.checkDeviceType();
      }
      await this.loadUnityConfig();
      await this.loadUnityScript();
    } catch (error) {
      throw Error('Error initializing Unity loader, please refresh your page');
    }
  }

  public checkDeviceType() {
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|Kindle|Silk/i;
    this.isMobileDevice = mobileRegex.test(navigator.userAgent);
  }

  openSystemRequirements(): void {
    this.dialog.open(SystemRequirementsDialogComponent, {
      width: '600px',
      panelClass: 'system-requirements-dialog',
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkDeviceType();
  }

  private async loadUnityConfig() {
    switch (this.queryData.categoryTitle) {
      case BuildType.PHOENEME_FAIR:
        this.unityConfig = getPhonemefairUnityConfig();

        if (!this.queryData.isDemo) {
          this.phonemefairSave = await firstValueFrom(
            this._phonemeFairService.phonemeFairControllerLoadGame(this.studentId),
          );
        }

        break;

      default:
        this.unityConfig = getUnityConfig();
        break;
    }
  }

  private loadUnityScript() {
    return new Promise<void>((resolve, reject) => {
      if (!this.script) {
        this.script = this._render.createElement('script');
        this.script.type = 'text/javascript';
        this.script.async = false;
        this._render.setAttribute(this.script, 'id', 'unityScript');
        this.script.src = this.unityConfig.loaderUrl;
        document.head.appendChild(this.script);
      } else if (this.script.src !== this.unityConfig.loaderUrl) {
        this.script.src = this.unityConfig.loaderUrl;
      }

      this.script.onload = () => {
        this.startComponent();
        resolve();
      };
    });
  }

  public waitForElm(selector: string) {
    return new Promise((resolve, reject) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });

      setTimeout(() => {
        observer.disconnect();
        reject();
      }, 5000);
    });
  }

  public async startComponent() {
    try {
      const canvas = await this.waitForElm('#gameCanvas');

      if (canvas) {
        createUnityInstance(canvas, this.unityConfig)
          .then(async (gameInstance) => {
            this.gameInstance = gameInstance;
            this.loading = false;

            if (!this.gameInstance) {
              throw new Error('Failed to load unity instance');
            }

            window['UnityAppMessage'] = (str) => this.UnityAppMessage(str);

            this.handleUnityClose.emit();
          })
          .catch(() => {
            this.closeUnitySession();
          });
      } else {
        this.closeUnitySession();
      }
    } catch (error) {
      this.closeUnitySession();
    }
  }

  public closeUnitySession() {
    this.endSession();
    this.endUnitySession.emit();

    if (!this.isMobileDevice) {
      this._snackBar.open(
        `Failed to load Unity instance, please reload the page and try again. If the problem persists contact our support`,
        'Close',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        },
      );
    }
  }

  public canRemoveScript(): boolean {
    return document && !!document.getElementById('unityScript');
  }

  public async endSession() {
    if (this.canRemoveScript()) {
      const script = document.getElementById('unityScript');
      script.remove();
    }

    this.script = null;

    if (this.gameInstance) {
      await this.gameInstance.Quit();
      this.gameInstance = null;
    }
  }

  ngOnDestroy(): void {
    this.endSession();
  }

  public reloadComponent() {
    this.gameInstance = null;
    this.loading = true;

    const canvas: HTMLElement = document.querySelector('#gameCanvas');
    createUnityInstance(canvas, this.unityConfig).then(
      (gameInstance) => ((this.gameInstance = gameInstance), (this.loading = false)),
    );
  }

  public setFullscreen() {
    this.gameInstance.SetFullscreen(1);
  }

  private async startUnity() {
    let values;

    if (this.gameDataOverwrite) {
      values = this.gameDataOverwrite;
    } else {
      let sessionDetails;

      if (this.overwriteSessionDetails) {
        sessionDetails = this.overwriteSessionDetails;
      } else {
        sessionDetails = await this.rest.get(
          `session/sessionConfigs/${this.queryData.lang}/${this.queryData.theme || classic}`,
          {
            params: new HttpParams()
              .set('week', this.queryData.week)
              .set('level', this.queryData.level)
              .set('category', this.queryData.category)
              .set('session', this.queryData.session.toString()),
            msg: 'Could not get session',
          },
        );
      }

      const {
        configs: { sessionData, parcels, dictionaries },
      } = sessionDetails;

      const categoryTag = this.selectedLesson ? this.selectedLesson.name.toLowerCase() : '';

      const savedGame = this.savedGameProgress.find(
        (s) =>
          s.tag.toLowerCase() === categoryTag &&
          (get(s.metadata, 'theme') === this.studentThemeId || get(s.metadata, 'theme') === this.queryData.theme),
      );
      let score = 0;

      if (savedGame) {
        for (let i = 0; i < savedGame.metadata.exerciseList.length; i++) {
          score = score + savedGame.metadata.exerciseList[i].score;
        }
      }

      if (this.shouldRemoveIntroVideo() && get(sessionData, '[0].type') === VIDEO) {
        sessionData.splice(0, 1);
      }

      values = {
        env: {
          dataPath: environment.WEBGL_BASE_URL,
          overlayIndex: 1,
        },
        savedState: {
          currentScore: score,
          currentExerciseIndex: get(savedGame, 'metadata.index', 0),
          finishedExercises: get(savedGame, 'metadata.exerciseList', []),
        },
        progressSave: {
          ...this.phonemefairSave,
        },
        isDemo: this.queryData.isDemo,
        gameDuration: this.queryData.gameDuration,
        student: this.student,
        age: this.studentHelper._transformStudentAge(this.student),
        exercises: sessionData,
        configs: {
          dictionaries,
          parcels,
        },
      };
    }

    values = this.b64EncodeUnicode(JSON.stringify(values));
    if (this.gameInstance) {
      this.gameInstance.SendMessage(
        'Comm',
        'PortalMessageGatewayFrom',
        '{"message":"StartSession","values":"' + values + '"}',
      );
    }
  }

  private async UnityAppMessage(str: string) {
    if (!str) {
      return;
    }

    this.logger.info('UnityAppMessage', str);
    const unityMessage = JSON.parse(str);

    switch (unityMessage.message) {
      case UnityMessages.APP_NOT_READY:
        break;
      case UnityMessages.APP_READY:
        await this.startUnity();
        break;
      case UnityMessages.SAVE_PROGRESS:
        this.saveProgress.emit(unityMessage);
        break;
      case UnityMessages.SAVE_PHONEMEFAIR_PROGRESS:
        this.savePhonemeFairProgress(unityMessage);
        break;
      case UnityMessages.SKIPED_EXERCISE:
        this.addSkipedExerciseWarning(unityMessage);
        break;
      case UnityMessages.SESSION_COMPLETE:
        this.sessionComplete.emit(unityMessage);
        break;
      case UnityMessages.DEMO_COMPLETE:
        this.endSession();
        this.sessionComplete.emit();
        break;
      default:
        break;
    }
  }

  b64EncodeUnicode = (str) => {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    const parsedStr = UNICODE_OVERRIDE.reduce((acc, [key, value]) => {
      return acc.replace(new RegExp(key, 'g'), value);
    }, str);

    return btoa(
      encodeURIComponent(parsedStr).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return String.fromCharCode('0x' + p1);
      }),
    );
  };

  public shouldRemoveIntroVideo(): boolean {
    const assementProgress = this.studentProgress.find((p) => p.tag === progressTypes.ASSESSMENT);

    return assementProgress && get(this.queryData, 'category') === progressTypes.ASSESSMENT ? true : false;
  }

  private async UnityFinishMessages(unityMessage) {
    if (unityMessage) {
      this.sessionComplete.emit(unityMessage);
    }
  }

  private addSkipedExerciseWarning(unityMessage: string) {
    const unityMessageValues = JSON.parse(get(unityMessage, 'values'));

    if (isEmpty(unityMessageValues)) {
      return;
    }

    const skipData: SessionSkipData = unityMessageValues;
    const studentInfo = {
      name: skipData.studentInfo.name,
      levelPlayed: skipData.studentInfo.level,
      sessionPlayed: skipData.studentInfo.session,
      studentLanguage: skipData.studentInfo.language,
      gameType: skipData.gameType,
    };
    captureException(new Error('A game exercise was skiped by lack of content'), {
      data: {
        method: 500,
        url: '',
        body: studentInfo,
        code: '',
      },
    });
  }

  private async savePhonemeFairProgress(unityMessage: string) {
    if (this.isDebugMode) {
      return;
    }

    const unityMessageValues = JSON.parse(get(unityMessage, 'values'));

    const body: Phonemefair = {
      studentId: this.studentId,
      ...unityMessageValues,
    };

    await firstValueFrom(this._phonemeFairService.phonemeFairControllerSaveGame(body));
  }
}
