import { Component, ElementRef, isDevMode, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { domainUrl } from 'src/app/services/auth/auth-constants';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';
import { PortalTypeService } from 'src/app/services/portal/portal-type.service';
import { clamp } from 'src/app/shared/helpers/clamp.function';
import { styleElement } from 'src/app/shared/helpers/styleElement.function';
import { styleElements } from 'src/app/shared/helpers/styleElements.function';
import { UserType } from 'src/app/shared/interfaces';
import { Styles } from 'src/app/shared/styles/lsw.styles';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'home-orgs-portal-hero',
  templateUrl: './orgs-portal-hero.component.html',
  styleUrls: ['./orgs-portal-hero.component.scss'],
})
export class OrgsPortalHeroComponent implements OnInit {
  orgName = this._portalType.getOrgName;
  public isSchool: boolean = false;

  @ViewChild('title') title: ElementRef;
  @ViewChild('box') box: ElementRef;
  @ViewChildren('panel') panels: QueryList<ElementRef>;
  @ViewChildren('paragraph') paragraphs: QueryList<ElementRef>;
  @ViewChildren('button') buttons: QueryList<ElementRef>;
  @ViewChildren('logo') logos: QueryList<ElementRef>;

  constructor(
    private _confirm: ConfirmationService,
    private _auth: AuthService,
    private _portalType: PortalTypeService,
    private _renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.isSchool = this._portalType.checkIfIsSchool();
  }

  ngAfterViewInit() {
    styleElement(this._renderer, this.title, {
      color: Styles.Colours.PRIMARY_COLOUR,
      textAlign: 'center',
      fontSize: clamp(1, 4),
      fontFamily: Styles.Fonts.PRIMARY_FONT,
      padding: `${clamp(1, 3)} ${clamp(1, 3)}`,
      marginBottom: 0,
      paddingBottom: 0,
    });

    styleElement(this._renderer, this.box, {
      display: 'flex',
      gap: clamp(1, 4),
      justifyContent: 'center',
      alignItems: 'center',
      padding: `${clamp(1, 4)} ${clamp(1, 4)}`,
    });

    styleElements(this._renderer, this.panels, {
      border: `${clamp(0.1, 0.1)} solid ${Styles.Colours.PRIMARY_COLOUR}`,
      gap: clamp(1, 1.2),
      padding: clamp(1, 4),
      boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
      maxWidth: '32rem',
      minWidth: '18rem',
      minHeight: '30rem',
      display: 'grid',
      gridTemplateRows: `min-content 6rem min-content 1fr`,
      borderRadius: '8px',
    });

    styleElements(this._renderer, this.paragraphs, {
      fontFamily: Styles.Fonts.PRIMARY_FONT,
      fontSize: clamp(1, 1.5),
      marginBottom: 0,
      alignSelf: 'center',
    });

    styleElements(this._renderer, this.buttons, {
      fontFamily: Styles.Fonts.PRIMARY_FONT,
      fontSize: clamp(1, 1.5),
      justifySelf: 'flex-start',
    });

    styleElements(this._renderer, this.logos, {
      marginBottom: clamp(1, 2),
    });
  }

  // B2C Signup --

  public b2cSignup() {
    if (!this.isOnlineSignUpAllowed()) {
      this._confirm.createConfirmation(
        'Alert',
        'This organization does not allow online registration. Please contact the provider for registration.',
        'Close',
        undefined,
        '400px',
        true,
        '/',
      );

      return;
    } else {
      this._auth.showSignUpLock(UserType.B2C);
    }
  }

  private isOnlineSignUpAllowed(): boolean {
    const provider = this._portalType.getPortalOwner;

    if (provider.disableStripeWarning) {
      return true;
    } else if (!provider.disableStripeWarning && !provider.allowSignup) {
      return false;
    } else {
      return true;
    }
  }

  // Login --

  public login() {
    if (!this._portalType.chekIfIsOrgPortal || isDevMode()) {
      this._auth.show();
    } else {
      // if the user is trying to login using a org portal url, first redirect it to our root domain so auth0 dont block the cookies

      window.location = ('//' + environment.ROOT_DOMAIN_URL + domainUrl + '/?orgPortal=true') as string &
        globalThis.Location;
    }
  }
}
