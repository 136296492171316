.disabledBtn {
  background: grey !important;
}

.submitBtn {
  background-color: #3a3372 !important;
  color: white !important;
}

.calendar {
  border-radius: 5px;

  .header {
    color: white;
    background-color: #3a3372;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-family: 'MontSerrat';
    padding: 20px;
    margin: auto;
  }

  .calendarDaysHeader {
    width: fit-content;
    .days {
      border: solid 4px #3a3372;
      font-size: 18px;
      font-weight: 550;
      padding: 15px;
      border-top: 10px solid #3a3372;
      border-radius: 10px;
      width: 80px;
      text-align: center;
      cursor: pointer;
    }
  }
  .selectedDay {
    color: white !important;
    background: #3a3372 !important;
  }

  .confirmButton {
    padding: 20px;
    button {
      color: white;
      background: #3a3372;
    }
  }
}

.calendarContainer {
  width: 700px;
  .calendarHeader {
    background-color: #3a3372;
    color: white;
    padding: 10px;
    div {
      font-size: 30px;
    }

    fa-icon {
      font-size: 15px;
    }
  }
  .calendarFooter {
    padding: 10px !important;
    div {
      span.label {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin: 2px;
      }
      font-size: 13px;
    }
  }

  .submit {
    padding: 10px !important;
  }
}

.cal-month-view {
  max-width: 100% !important;
  max-height: '640px' !important;
  padding: 10px !important;
  .cal-day-badge {
    display: none;
  }
}

.month {
  padding: 10px !important;
}

.calendarIcon {
  cursor: pointer;
}

.optionsContainer {
  .mainContainer {
    width: 300px;
    div.header {
      padding: 10px;
      color: white;
      background-color: #3a3372;
      text-align: center;
      font-size: 20px;
      font-weight: 550;
      font-family: 'MontSerrat';
    }

    div.options {
      div.optionsHeader {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
      }
    }

    .hideInput {
      display: none;
    }
  }
}
