import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import { BehaviorSubject, debounceTime, fromEvent, Observable, Subscription, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleService } from 'src/app/services/roles/role.service';
import { SideMenuPreferences, ResponsiveView } from './side-menu.models';
import { setResponsiveView, getSideMenuPreferences, STORAGE_KEY, shouldShowSideMenu } from './side-menu.helpers';

const SIDE_MENU_LOCAL_STORAGE_KEY = 'userSideMenuPreference';

export type SideMenuSection =
  | 'none'
  | 'users'
  | 'admin'
  | 'configuration'
  | 'programs'
  | 'demo'
  | 'tutorials'
  | 'documents'
  | 'region';

export interface SideMenuState {
  preferences: BehaviorSubject<SideMenuPreferences>;
  view: BehaviorSubject<ResponsiveView>;
  canShow: BehaviorSubject<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class SideMenuService implements OnDestroy {
  // State --
  private _state: SideMenuState = {
    preferences: new BehaviorSubject<SideMenuPreferences>(getSideMenuPreferences()),
    view: new BehaviorSubject<ResponsiveView>(setResponsiveView(window.innerWidth)),
    canShow: new BehaviorSubject<boolean>(shouldShowSideMenu(this._auth, this._roles)),
  };

  get preferences$(): Observable<SideMenuPreferences> {
    return this._state.preferences.asObservable();
  }

  get view$(): Observable<ResponsiveView> {
    return this._state.view.asObservable();
  }

  get canShow$(): Observable<boolean> {
    return this._state.canShow.asObservable();
  }

  get showDashboardRoute() {
    return (
      this._roles.isPortalOwner() || this._roles.isOrgOwner() || this._roles.isOrgAdmin() || this._roles.isOrgManager()
    );
  }

  public isMobile() {
    return this._state.view.value == 'mobile';
  }

  public isOpen() {
    return this._state.preferences.value.isOpen;
  }

  public refreshSideMenuVisibility() {
    this._state.canShow.next(shouldShowSideMenu(this._auth, this._roles));
  }

  // Members --

  private _subs: Subscription[] = [];

  private _isDebugMode: boolean = false;

  // Lifecycle --

  constructor(private _auth: AuthService, private _roles: RoleService) {
    this._init();
  }

  private _init(): void {
    this._subs.push(
      // Setup handler to watch for screen size changes and debouce for better performance
      fromEvent(window, 'resize')
        .pipe(
          tap(() => {
            // close mobile menu when resizing
            if (this.isMobile() && this.isOpen()) {
              this.close();
            }
          }),
          debounceTime(10),
        )
        .subscribe(() => {
          this._state.view.next(setResponsiveView(window.innerWidth));
        }),

      // log every state change if debug mode is on
      this._state.preferences
        .pipe(
          tap((data) => {
            if (isDevMode() && this._isDebugMode) {
              console.log({ sidemenu: data });
            }
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
    this._subs = [];
  }

  // Private --

  private _save(state: SideMenuPreferences): void {
    this._state.preferences.next(state);
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  }

  private updateSection(section: SideMenuSection): void {
    const preferences = this._state.preferences.value;

    if (preferences.sections[section]) {
      preferences.sections[section] = false;
    } else {
      preferences.sections[section] = true;
    }

    this._save(preferences);
  }

  // Public --

  public toggleSection(section: string): void {
    switch (section) {
      case 'Users':
      case 'Students':
        this.updateSection('users');
        break;
      case 'Admin':
        this.updateSection('admin');
        break;
      case 'Configuration':
        this.updateSection('configuration');
        break;
      case 'Programs':
        this.updateSection('programs');
        break;
      case 'Demo':
        this.updateSection('demo');
        break;
      case 'Tutorials':
        this.updateSection('tutorials');
        break;
      case 'Documents':
        this.updateSection('documents');
        break;
      case 'Region':
        this.updateSection('region');
        break;
      default:
        console.error('Could not toggle section:', section);
        return;
    }
  }

  public toggle(): void {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  }

  public open() {
    const preferences = this._state.preferences.value;
    preferences.isOpen = true;
    this._save(preferences);
  }

  public close() {
    const preferences = this._state.preferences.value;
    preferences.isOpen = false;
    this._save(preferences);
  }
}
